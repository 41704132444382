import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as PopoverRadix from "@radix-ui/react-popover";
import Button from "./button";
import classnames from "classnames";
import css from "./popover.module.css";
const Context = React.createContext({});
const Root = (props_) => {
    const props = React.useMemo(() => {
        return {
            ...props_,
            closeOnScroll: props_.closeOnScroll ?? false,
        };
    }, [props_]);
    const { open, onOpenChange, closeOnScroll } = props;
    const existingContext = React.useContext(Context);
    const enrichedContext = {
        ...existingContext,
        ...props,
    };
    React.useLayoutEffect(() => {
        const onScroll = () => {
            if (closeOnScroll) {
                onOpenChange && onOpenChange(false);
            }
        };
        if (open) {
            window.addEventListener("scroll", onScroll);
        }
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [open, closeOnScroll]);
    return (_jsx(Context.Provider, { value: enrichedContext, children: _jsx(PopoverRadix.Root, { ...props }) }));
};
const Trigger = React.forwardRef((props_, forwardedRef) => {
    const props = React.useMemo(() => {
        return {
            ...props_,
            ref: forwardedRef,
            variant: props_.variant ?? "icon",
            color: props_.color ?? "tertiary",
        };
    }, [props_]);
    const classesTrigger = classnames(css.trigger, props_.classNames?.trigger);
    return (_jsx(PopoverRadix.Trigger, { asChild: true, className: classesTrigger, children: props_.asChild ? props_.children : _jsx(Button, { ...props }) }));
});
const Portal = PopoverRadix.Portal;
const Content = React.forwardRef((props_, forwardedRef) => {
    const context = React.useContext(Context);
    const props = React.useMemo(() => {
        return {
            ...props_,
            side: props_.side ?? "bottom",
            collisionBoundary: props_.collisionBoundary ?? context.zone,
        };
    }, [props_, context]);
    const refLocal = React.useRef(null);
    React.useEffect(() => {
        if (props.collisionBoundary === undefined) {
            console.warn("You can use a <Popover> without declaring a collisionBoundary or a <Popover.Zone> higher in the component tree but if so: " +
                "its boundaries won't be defined ; it's possible that it would appear in funky places (under the navigation menu for example).");
        }
    }, [context, props.collisionBoundary]);
    const classesContent = classnames(css.content, props.classNames?.content);
    const classesArrow = classnames(css.arrow, props.classNames?.arrow);
    const { children, arrow, classNames, ...spreadedProps } = props;
    return (_jsxs(PopoverRadix.Content, { ...spreadedProps, ref: (node) => {
            refLocal.current = node;
            if (typeof forwardedRef === "function") {
                forwardedRef(node);
            }
            else if (forwardedRef) {
                forwardedRef.current = node;
            }
        }, className: classesContent, children: [children, arrow && _jsx(PopoverRadix.Arrow, { className: classesArrow })] }));
});
const Zone = ({ children }) => {
    const [node, setNode] = React.useState(undefined);
    const getChildrenZone = (node) => {
        const nodeChildrenArray = node?.children ? Array.from(node.children) : null;
        if (!Array.isArray(nodeChildrenArray)) {
            return nodeChildrenArray;
        }
        else {
            if (nodeChildrenArray?.length > 1) {
                throw new Error("Popover.Zone, erreur: Une Popover.Zone ne peut avoir qu'un seul children -ou qu'un seul children de children (et ainsi de suite...) si son children est en display: contents-.");
            }
            else if (nodeChildrenArray?.length === 1) {
                if (window.getComputedStyle(nodeChildrenArray[0]).display === "contents") {
                    return getChildrenZone(nodeChildrenArray[0]);
                }
                else {
                    return nodeChildrenArray[0];
                }
            }
            else {
                return null;
            }
        }
    };
    const childrenZone = node !== undefined ? getChildrenZone(node) : null;
    return (_jsx(Context.Provider, { value: { zone: childrenZone }, children: _jsx("div", { ref: (node) => {
                if (node !== null) {
                    setNode(node);
                }
            }, className: css.zone, children: children }) }));
};
Root.Trigger = Trigger;
Root.Portal = Portal;
Root.Content = Content;
Root.Zone = Zone;
export default Root;
